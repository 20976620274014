<template>
	<div id="componentWechatInsurance">
		<el-dialog title="微信保险管理" 
			:visible.sync="innerVisible" 
			width="800px" 
			destroy-on-close 
			:close-on-click-modal="false" 
			:close-on-press-escape="false"
			@open="getList('cover')"
			@close="closeDialog">
			<div v-loading="loading">
				<div style="margin-bottom: 18px;">
					<div>
						<el-button type="primary" size="small" @click="openSignForm()">申请开通微信保险</el-button>
						<!-- <el-button type="primary" size="small" @click="getList()">刷新列表</el-button> -->
					</div>
					<el-alert
						style="margin-top: 10px;"
						:closable="false"
					    title="请提前完成微信发薪绑定,未绑定微信发薪不可开通保险!!!"
						description="一个特约商户号仅可绑定一个保险,请勿重复开通!!!"
						show-icon
					    type="warning">
					</el-alert>
				</div>
				<el-table :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
					<el-table-column label="主体名称" prop="laborName" align="center"/>
					<el-table-column label="绑定特约商户号" prop="laborMchId" align="center" width="160"/>
					<el-table-column label="审核状态" prop="status" align="center" width="140">
						<template slot-scope="scope">
							<span style="color:#1989fa;" v-if="scope.row.status=='AUDITING'">审核中</span>
							<span style="color:#67C23A;" v-if="scope.row.status=='PASS'">已通过</span>
							<span style="color:#F56C6C;" v-if="scope.row.status=='REJECT'">审核被驳回</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center" width="140">
						<template slot-scope="scope">
							<template>
								<el-button type="primary" size="small" @click="uploadSign(scope.row.laborMchId,scope.$index)">更新签约情况</el-button>
							</template>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
		
		<el-dialog
		    width="600px"
		    title="申请开通微信保险"
		    :visible.sync="applyShow"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			top="5vh"
			destroy-on-close>
			<div v-loading="applyLoading">
				<el-alert
					style="margin-bottom: 20px;"
					:closable="false"
				    title="审核流程未结束前,请勿重复申请！！！"
					show-icon
				    type="warning">
				</el-alert>
				<el-form ref="signRef" :model="signForm" :rules="rules" label-width="130px">
					<el-form-item label="特约商户号" prop="merchat_id">
						<el-select size="small" v-model="signForm.merchat_id" style="width: 100%;" placeholder="请选择特约商户号">
							<template v-for="item in weChatList">
								<el-option :key="item.id" :label="item.company_name" :value="item.id" :disabled="calcDisabled(item)">
								    <span style="float: left">{{ item.company_name }}</span>
								    <span v-if="calcDisabled(item)" style="float: right;">已使用</span>
								</el-option>
							</template>
						</el-select>
					</el-form-item>
					<el-form-item label="统一社会信用码" prop="socialCreditCode">
						<el-input size="small" v-model="signForm.socialCreditCode" placeholder="请输入商户号同主体的统一社会信用码"></el-input>
					</el-form-item>
					<el-form-item label="对公账户账号" prop="accountLimit">
						<el-input size="small" v-model="signForm.accountLimit" placeholder="请输入商户号同主体对公账户账号"></el-input>
					</el-form-item>
					<el-form-item label="联系人姓名" prop="operatorName">
						<el-input size="small" v-model="signForm.operatorName" placeholder="请输入联系人姓名"></el-input>
					</el-form-item>
					<el-form-item label="联系人电话" prop="contact">
						<el-input size="small" v-model="signForm.contact" placeholder="请输入联系人电话"></el-input>
					</el-form-item>
					<el-form-item label="联系人身份证号" prop="operatorCredentialNo">
						<el-input size="small" v-model="signForm.operatorCredentialNo" placeholder="请输入联系人身份证号"></el-input>
					</el-form-item>
					<el-form-item label="营业执照" prop="file">
						<div class="uploadbox">
							<div class="imagebox" v-if="signForm.file" @click="$refs.imageFile.click()">
								<el-image class="img" :src="resoleFileUrl(signForm.file)" fit="cover"></el-image>
								<i class="el-icon-error del" @click.stop="signForm.file = ''"></i>
							</div>
							<div v-else class="imagebox add" @click="$refs.imageFile.click()">
								<i class="el-icon-upload icon"></i>
								<span class="text">上传营业执照</span>
							</div>
							<input class="imageFile" ref="imageFile" accept="image/*" type="file" :multiple="false" @change="uploadImage" />
						</div>
					</el-form-item>
				</el-form>
				
				<div style="text-align: center;margin-top: 12px;">
					<el-button type="primary" size="medium" @click="applyShow=false">取消</el-button>
					<el-button type="primary" size="medium" @click="getSignLink()">确认</el-button>
				</div>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	import {eltips} from '@/util/util.js'
	export default {
		name: "componentWechatInsurance",
		data() {
			return {
				loading:false,
				weChatList:[],
				list:[],
				signForm:{
					merchat_id:'',
					socialCreditCode:'',
					accountLimit:'',//对公账户
					operatorName:'',//联系人姓名
					contact:'',//联系人电话,
					operatorCredentialNo:'',//联系人身份证
					file:null,//营业执照
				},
				rules:{
					merchat_id:[{required: true, message: '请输入选择特约商户号', trigger: 'change'}],
					socialCreditCode:[{required: true, message: '请输入统一社会信用码', trigger: 'blur'}],
					accountLimit:[{required: true, message: '请输入同主体对公账户', trigger: 'blur'}],
					operatorName:[{required: true, message: '请输入联系人姓名', trigger: 'blur'}],
					contact:[{required: true, message: '请输入联系人电话', trigger: 'blur'}],
					operatorCredentialNo:[{required: true, message: '请输入联系人身份证号', trigger: 'blur'}],
					file:[{required: true, message: '请上传营业执照', trigger: 'blur'}],
				},
				applyLoading:false,
				applyShow:false,
			};
		},
		props:{
			visible: {
				type: Boolean,
				default: false
			},
			shopId: {
				type: [String,Number],
				default: ''
			},
		},
		computed: {
		    innerVisible: {
		      get() {
		        return this.visible;
		      },
		      set(value) {
		        this.$emit("update:visible", value);
		      },
		    },
		},
		created() {
			
		},
		methods:{
			resoleFileUrl(file){
				const windowURL = window.URL || window.webkitURL;
				return windowURL.createObjectURL(file)
			},
			uploadImage(e){
				if(e.target.files.length==0) return this.$refs.imageFile.value = '' 
				let file = e.target.files[0]
				if(!['image/jpeg','image/png','image/jpg'].includes(file.type)){
					eltips('仅支持png、jpg、jpge格式图片');
					this.$refs.imageFile.value = '';
					return
				}  
				this.signForm.file = file
				this.$refs.imageFile.value = '';
			},
			calcDisabled(e){
				let isDisabled = false
				this.list.map(item=>{
					if(item.laborMchId==e.sub_mchid&&item.status!='REJECT'){
						isDisabled = true
					}
				})
				return isDisabled
			},
			//更新签约状态
			uploadSign(laborMchId,index){
				this.loading = true
				const data = {
					laborMchId:laborMchId,
					is_update:1,
					shop_id:this.shopId
				}
				this.axios.get(`/api/main/Wechat/laborQuery`,{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						let obj = this.list[index]
						obj.status = res.data.auditState
						this.list.splice(index,1,obj)
						eltips(res.msg,'success')
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			closeDialog(){
				this.$emit('update:visible',false)
			},
			//获取签约链接
			openSignForm(){
				this.signForm = {
					merchat_id:'',
					socialCreditCode:'',
					accountLimit:'',//对公账户
					operatorName:'',//联系人姓名
					contact:'',//联系人电话,
					operatorCredentialNo:'',//联系人身份证
					file:null,//营业执照
				}
				this.applyShow = true
			},
			getSignLink(){
				this.$refs['signRef'].validate((valid) => {
					if(!valid) return
					let formData = new FormData()
					formData.append('shop_id',this.shopId)
					formData.append('merchat_id',this.signForm.merchat_id)
					formData.append('socialCreditCode',this.signForm.socialCreditCode)
					formData.append('accountLimit',this.signForm.accountLimit)
					formData.append('operatorName',this.signForm.operatorName)
					formData.append('contact',this.signForm.contact)
					formData.append('operatorCredentialNo',this.signForm.operatorCredentialNo)
					formData.append('file',this.signForm.file)
					this.applyLoading = true
					this.axios.post('/api/main/Wechat/laborApply',formData).then(res=>{
						this.applyLoading = false
						if(res.code==200){
							this.applyShow = false
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			getList(cover){
				if(cover){
					this.list = []
					this.getWeChatMerchantList()
				} 
				let data = { shop_id:this.shopId }
				this.loading = true
				this.axios.get('/api/main/Wechat/laborList',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.list = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			getWeChatMerchantList(){
				let data = { shop_id:this.shopId }
				this.axios.get('/api/main/SysTem/getWxMerchant',{params:data}).then(res=>{
					if(res.code==200){
						this.weChatList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#componentWechatInsurance{
		.uploadbox{
			.imagebox{
				width: 200px;
				height: 120px;
				position: relative;
				.img{
					width: 100%;
					height: 100%;
				}
				.del{
					position: absolute;
					right: -6px;
					top: -8px;
					font-size: 20px;
					color: #F56C6C;
					border-radius: 50%;
					background: #FFF;
					cursor: pointer;
				}
			}
			.add{
				border: 1px dashed #1989fa;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #1989fa;
				cursor: pointer;
				.icon{
					font-size: 60px;
				}
				.text{
					font-size: 15px;
				}
			}
			.imageFile{
				width: 0;
				height: 0;
				opacity: 0;
				position: absolute;
				left: 0;
				border: 0;
				z-index: -1;
			}
		}
	}
</style>