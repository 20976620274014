<template>
	<div id="invoiceApply">
		<div class="headbox">
			<el-form :inline="true">
				<el-form-item label="企业名称:">
					<el-input size="small " v-model="company_name" placeholder="搜索企业名称" clearable style="width: 200px;"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		
		<div class="tablebox">
			<el-tabs v-model="Invoiced_status" @tab-click="changepage(1)">
			    <el-tab-pane label="待开票" name="1"></el-tab-pane>
			    <el-tab-pane label="已开票" name="3"></el-tab-pane>
			    <el-tab-pane label="拒绝开票" name="2"></el-tab-pane>
			</el-tabs>
			<el-table v-loading="loading" :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
				<el-table-column prop="shop.company_name" label="申请企业" align="center"></el-table-column>
				<el-table-column prop="company_name" label="开票主体" align="center"></el-table-column>
				<el-table-column prop="socialCreditCode" label="纳税人识别号" align="center"></el-table-column>
				<el-table-column prop="amount" label="开票金额" align="center"></el-table-column>
				<el-table-column prop="amount" label="开票项目" align="center">
					<template slot-scope="scope">
						<div>技术服务费</div>
					</template>
				</el-table-column>
				<el-table-column prop="created_at" label="申请时间" align="center"></el-table-column>
				<el-table-column prop="amount" label="当前状态" align="center" width="160">
					<template slot-scope="scope">
						<div v-if="scope.row.Invoiced_status==1" style="color:#409EFF;">开票中</div>
						<div v-if="scope.row.Invoiced_status==2" style="color:#F56C6C;">开票失败</div>
						<div v-if="scope.row.Invoiced_status==3" style="color:#67C23A;">开票成功</div>
					</template>
				</el-table-column>
				<el-table-column prop="amount" label="处理结果" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.Invoiced_status==2" style="color:#F56C6C;">{{scope.row.remarks}}</div>
						<div v-if="scope.row.Invoiced_status==3">
							<el-button type="text" style="color: #409EFF;" @click="openInvoiceFile(scope.row.Invoiced_file)">查看发票</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="amount" label="操作" align="center" width="220">
					<template slot-scope="scope">
						<template v-if="scope.row.Invoiced_status==1">
							<el-button type="text" style="color: #409EFF;" @click="openUpload(scope.row)">通过并上传发票</el-button>
							<el-button type="text" style="color: #F56C6C;" @click="refuse(scope.row.id)">驳回申请</el-button>
						</template>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page" :page-size="10"
				layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<el-dialog
			title="通过申请并上传发票"
			:visible.sync="uploadShow"
			width="600px"
			:close-on-click-modal="false"
			destroy-on-close>
			<div class="uploadFormbox" v-loading="uploadLoading">
				<el-form :model="uploadForm" ref="uploadform" label-width="130px" label-position="left" :rules="uploadRules">
					<el-form-item label="我方企业名称：">
						四川赤道猩数字软件科技有限公司 
						<el-button type="text" icon="el-icon-document-copy" style="margin-left:8px;" @click="copyText('四川赤道猩数字软件科技有限公司')">复制</el-button>
					</el-form-item>
					<el-form-item label="我方企业税号：">
						91510105MA6CH7E18D
						<el-button type="text" icon="el-icon-document-copy" style="margin-left:8px;" @click="copyText('91510105MA6CH7E18D')">复制</el-button>
					</el-form-item>
					
					<div style="margin: 12px 0;height: 1px;width:100%;background: #ccc;"></div>
					<el-form-item label="申请开票企业：" required>
						{{uploadForm.shop_name}}
					</el-form-item>
					<el-form-item label="申请开票主体：" required>
						{{uploadForm.company_name}}
						 <el-button type="text" icon="el-icon-document-copy" style="margin-left:8px;" @click="copyText(uploadForm.company_name)">复制</el-button>
					</el-form-item>
					<el-form-item label="纳税人识别号：" required>
						{{uploadForm.socialCreditCode}}
						<el-button type="text" icon="el-icon-document-copy" style="margin-left:8px;" @click="copyText(uploadForm.socialCreditCode)">复制</el-button>
					</el-form-item>
					<el-form-item label="上传发票：" prop="file">
						<div v-if="uploadForm.Invoiced_file" class="filebox" >
							<div><i class="el-icon-document"></i> {{uploadForm.Invoiced_file.name}}</div>
							<div class="reload" @click="uploadForm.Invoiced_file = null">取消选择</div>
						</div>
						<div v-else class="uploadbox" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover" @click="$refs['upload-input'].click()">
							<input ref="upload-input" class="upload-input" type="file" accept=".jpg, .png, .pdf" @change="handleClick">
							<i class="el-icon-upload uploadIcon"></i>
							<div>拖动发票文件至此处或<span style="color: #1989fa;">点击上传</span></div>
						</div>
					</el-form-item>
				</el-form>
				<div style="text-align: center;margin-top: 28px;">
					<el-button @click="uploadShow = false">取 消</el-button>
					<el-button type="primary" @click="uploadConfirm()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {eltips,qiniuUpload} from '@/util/util.js'
	export default {
		name: "invoiceApply",
		data() {
			var existFileRule = (rule, value, callback) => {
				if (!this.uploadForm.Invoiced_file) return callback(new Error('请选择发票文件'));
				callback();
			};
			return {
				loading:false,
				list:[],
				page:1,
				total:0,
				shop_name:'',
				company_name:'',
				Invoiced_status:'1',
				applyId:'',
				applyDate:'',
				uploadShow:false,
				uploadLoading:false,
				uploadForm:{
					shop_name:'',
					company_name:'',
					socialCreditCode:'',
					Invoiced_file:null,
				},
				
				uploadRules:{
					file: [{ required: true, validator: existFileRule, trigger: 'change' }],
				},
			};
		},
		created() {
			this.getList()
		},
		methods:{
			openInvoiceFile(file){
				window.open(file)
			},
			uploadConfirm(){
				this.$refs['uploadform'].validate(async (valid) => {
					if (!valid) return;
					
					this.loading = true;
					let data = {
						id:this.applyId,
						type:1,
						Invoiced_file:''
					}
					
					this.uploadLoading = true
					
					//获取七牛云token
					const qiniuTokenRes = await this.axios.post('/api/upload/get_qiniu_token')
					if(qiniuTokenRes.code!=200){
						eltips('上传发票失败，请稍后重试')
						this.uploadLoading = false;
						return
					}
					
					//上传文件
					const userKey = `${this.uploadForm.company_name}-${this.applyDate}申请开票-技术服务费-${new Date().getTime()}`
					const fileUrl = await qiniuUpload(this.uploadForm.Invoiced_file,userKey,qiniuTokenRes.data)
					if(!fileUrl){
						eltips('上传发票失败，请稍后重试')
						this.uploadLoading = false;
						return
					}
					data.Invoiced_file = fileUrl
					
					this.axios.post('/api/main/Invoice/Invoice_approver',data).then(res=>{
						this.uploadLoading = false
						if(res.code==200){
							this.uploadShow = false
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			openUpload(row){
				this.applyId = row.id
				this.applyDate = `${new Date(row.created_at).getFullYear()}年${new Date(row.created_at).getMonth()+1}月${new Date(row.created_at).getDate()}日申请`
				this.uploadForm = {
					shop_name:row.shop.company_name,
					company_name:row.company_name,
					socialCreditCode:row.socialCreditCode,
					Invoiced_file:'',
				}
				this.uploadShow = true
			},
			refuse(id){
				this.$prompt('请输入驳回理由', '驳回申请', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    inputValidator(value) {
				    	if (!value) return '驳回理由不能为空'
				    	if (value.replace(/\s/g, '').length < 1) return '驳回理由不能为空'
				    }
				}).then(({value}) => {
				    this.axios.post('/api/main/Invoice/Invoice_approver',{id:id,remarks:value,type:2}).then(res=>{
						if(res.code==200){
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				}).catch(() => {});
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				this.loading = true;
				let data = {
					page:this.page,
					shop_name:this.shop_name,
					company_name:this.company_name,
					Invoiced_status:this.Invoiced_status,
				}
				this.axios.get('/api/main/Invoice/get_Invoice_apply',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			//上传文件
			handleClick(e){
				if(e.target.files.length==0) return this.$refs['upload-input'].value = null;
				const file = e.target.files[0]
				if(!this.isExcel(file)) return eltips('仅支持 .xlsx, .xls 文件','error');
				this.uploadForm.Invoiced_file = file
				this.$refs['upload-input'].value = null
				this.$refs['uploadform'].validateField('file')
			},
			handleDrop(e) {
				e.stopPropagation()
				e.preventDefault()
				const files = e.dataTransfer.files
				if (files.length !== 1) return eltips('请上传单个文件', 'error');
				const file = files[0]
				if(!this.isExcel(file)) return eltips('仅支持 .jpg, .png, .pdf 文件','error');
				this.uploadForm.Invoiced_file = file
				this.$refs['upload-input'].value = null
				this.$refs['uploadform'].validateField('file')
			},
			handleDragover(e) {
				e.stopPropagation()
				e.preventDefault()
				e.dataTransfer.dropEffect = 'copy'
			},
			isExcel(file) {
			  return /\.(jpg|png|pdf)$/.test(file.name)
			},
			//复制
			copyText(text) {
			  var textarea = document.createElement('textarea');
			  textarea.style.position = 'fixed';
			  textarea.style.opacity = 0;
			  textarea.value = text;
			  document.body.appendChild(textarea);
			  textarea.select();
			  document.execCommand('copy');
			  document.body.removeChild(textarea);
			  eltips('复制成功','success')
			},
		}
	};
</script>

<style lang="less" scoped>
	#invoiceApply{
		min-height: calc(100vh - 70px);
	}
	
	#invoiceApply .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	
	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
	
	.uploadFormbox /deep/ .el-form-item{
		margin-bottom: 10px;
	}
	
	.uploadFormbox /deep/ .el-form-item__label{
		color: #888;
	}
	
	.uploadFormbox /deep/ .el-form-item__content{
		color: #333;
	}
	
	.filebox{
		display: flex;
		align-items: center;
		justify-content: space-between;
		.reload{
			line-height: 1;
			color: #1989fa;
			cursor: pointer;
		}
	}
	
	.uploadbox{
		width: 100%;
		height: 200px;
		border: 1px solid #DCDFE6;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:hover{
			border-color: #1989fa;
			border-style: dashed;
		}
		.uploadIcon{
			font-size: 68px;
			color: #c0c4cc;
			line-height: 1;
			margin-bottom: 12px;
		}
		.upload-input{
		  display: none;
		  z-index: -9999;
		}
	}
</style>