<template>
	<div id="accountList">
		<div class="tablebox">
			<el-table v-loading="loading" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
				<el-table-column label="所属客户" prop="customer.name" align="center"/>
				<el-table-column label="到期时间" prop="customer.expiration_time" align="center"/>
				<el-table-column label="登录账号" prop="phone" align="center"/>
				<el-table-column label="品牌名称" prop="name" align="center"/>
				<el-table-column label="系统全称" prop="shop_name" align="center"/>
				<el-table-column label="公司全称" prop="company_name" align="center"/>
				<el-table-column label="费率管理" align="center" width="100">
					<template slot-scope="scope">
						<el-button type="text" @click="openRate(scope.row)">查看费率</el-button>
					</template>
				</el-table-column>
				<el-table-column label="保险管理" align="center" width="180">
					<template slot-scope="scope">
						<el-button type="text" @click="openAlipayInsurance(scope.row)">支付宝保险</el-button>
						<el-button type="text" @click="openWechatInsurance(scope.row)">微信保险</el-button>
					</template>
				</el-table-column>
				<el-table-column label="发薪管理" align="center" width="180">
					<template slot-scope="scope">
						<el-button type="text" v-if="hasPermissions(2804)" @click="openAlipay(scope.row)">支付宝发薪</el-button>
						<el-button type="text" v-if="hasPermissions(2660)" @click="openWechat(scope.row)">微信发薪</el-button>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="200">
					<template slot-scope="scope">
						<!-- <el-button type="text" v-if="hasPermissions(2659)">网商账户</el-button> -->
						<el-button type="text" v-if="hasPermissions(2658)" @click="restPassword(scope.row.id)">重置密码</el-button>
						<el-button type="text" v-if="hasPermissions(2661)" @click="openPremission(scope.row.id)">权限管理</el-button>
						<el-button type="text" v-if="hasPermissions(2662)" @click="openStyle(scope.row)">界面调整</el-button>
						<el-button type="text" @click="copylink(scope.row.shop_id)">复制登录地址</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page" :page-size="10"
				layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<el-dialog
			title="界面调整"
			:visible.sync="styleShow"
			v-if="styleShow"
			:close-on-click-modal="false" 
			:close-on-press-escape="false"
			width="600px">
			<div v-loading="saveLoading">
				<el-form :model="styleForm" label-width="108px" :rules="rules" ref="styleForm">
					<el-form-item label="系统全称：" prop="shop_name">
						<el-input size="small" type="text" v-model="styleForm.shop_name" placeholder="请输入系统全称" clearable></el-input>
					</el-form-item>
					<el-form-item label="招聘端名称：" prop="shop_name">
						<el-input size="small" type="text" v-model="styleForm.recruit_name" placeholder="请输入系统全称" clearable></el-input>
					</el-form-item>
					<el-form-item label="系统简介：" prop="introduce">
						<el-input size="small" type="textarea" :rows="3" v-model="styleForm.introduce" placeholder="请输入系统简介" clearable></el-input>
					</el-form-item>
					<el-form-item label="公司全称：" prop="company_name">
						<el-input size="small" type="text" v-model="styleForm.company_name" placeholder="请输入公司全称" clearable></el-input>
					</el-form-item>
					<el-form-item label="品牌名称：" prop="name">
						<el-input size="small" type="text" v-model="styleForm.name" placeholder="请输入品牌名称" clearable></el-input>
					</el-form-item>
					<el-form-item label="logo：" prop="logo">
						<div class="uploadbox">
							<div class="imgbox" v-if="styleForm.logo">
								<el-image class="img" :src="styleForm.logo" fit="cover" :preview-src-list="[styleForm.logo]"></el-image>
								<i class="el-icon-error delicon" @click="delImg('logo')"></i>
							</div>
							<div v-else class="imgbox" style="cursor: pointer;" @click="openPlugin('logo')">
								<i class="el-icon-picture addIcon"></i>
								<div class="addtips">上传logo</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="背景图：" prop="bj_img">
						<div class="uploadbox">
							<div class="imgbox" v-if="styleForm.bj_img">
								<el-image class="img" :src="styleForm.bj_img" fit="cover" :preview-src-list="[styleForm.bj_img]"></el-image>
								<i class="el-icon-error delicon" @click="delImg('bj_img')"></i>
							</div>
							<div v-else class="imgbox" style="cursor: pointer;" @click="openPlugin('bj_img')">
								<i class="el-icon-picture addIcon"></i>
								<div class="addtips">上传背景图</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="版权信息：">
						<el-switch
						  v-model="styleForm.copyright_is_show"
						  :active-value="1"
						  active-text="显示"
						  :inactive-value="0"
						  inactive-text="隐藏">
						</el-switch>
					</el-form-item>
				</el-form>
				
				<div style="text-align: center;margin-top: 18px;">
					<el-button size="medium" @click="styleShow = false">取 消</el-button>
					<el-button size="medium" type="primary" @click="saveStyle()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
		
		<el-dialog title="编辑权限" :visible.sync="permissionShow" width="600px" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false"
			@opened="getUserPermissions">
			<div style="max-height: 50vh;overflow-y: auto;">
				<el-tree ref="permissionTree" :data="permissionsList" show-checkbox :props="{ label: 'title', children: '_child' }" v-loading="permissionLoading"  node-key="id">
					<div slot-scope="{ node, data }" style="display: flex;align-items: center;width: 100%;">
						<div :style="{'color':data.type==3?'#666':'#000'}">{{ node.label }}</div>
						<div v-if="data.type==3" style="font-size: 14px;color: #666;">（操作权限）</div>
					</div>
				</el-tree>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="permissionShow = false">取 消</el-button>
				<el-button type="primary" :loading="permissionLoading" @click="setPermissions">确 定</el-button>
			</span>
		</el-dialog>
	
		<el-dialog title="微信发薪" :visible.sync="wechatShow" width="800px" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false"
			@opened="getWechatList()">
			<div v-loading="wechatLoading">
				<div style="margin-bottom: 18px;">
					<el-button type="primary" size="small" @click="openWechatAdd()">添加特约商户号</el-button>
					<el-alert
						style="margin-top: 10px;"
						:closable="false"
					    title="请在 微信支付服务商后台 完成客户的微工卡授权, 请勿添加未完成微工卡授权的商户号!!!"
						show-icon
					    type="warning">
					</el-alert>
				</div>
				<el-table :key="Math.random()" :data="wechatList" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
					<el-table-column label="商户主体名称" prop="company_name" align="center"/>
					<el-table-column label="特约商户号" prop="sub_mchid" align="center" width="160"/>
					<el-table-column label="添加时间" prop="created_at" align="center" width="160"/>
					<el-table-column label="操作" prop="created_at" align="center" width="120">
						<template slot-scope="scope">
							<el-button type="primary" size="small" @click="openWechatEdit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<el-dialog
			    width="600px"
			    :title="wechatId?'编辑商户号':'添加商户号'"
			    :visible.sync="wechatAddShow"
				destroy-on-close 
				:close-on-click-modal="false" 
			    append-to-body>
				<div v-loading="wechatAddLoading">
					<el-alert
						v-if="wechatId"
						style="margin-bottom: 20px;"
						:closable="false"
					    title="非特殊情况请勿编辑商户号,修改商户号将会导致部分出资记录错误!!!"
						show-icon
					    type="warning">
					</el-alert>
					<el-form label-width="100px">
						<el-form-item label="主体名称" required>
							<el-input size="small" v-model="company_name" placeholder="请输入商户主体名称"></el-input>
						</el-form-item>
						<el-form-item label="特约商户号" required>
							<el-input size="small" v-model="sub_mchid" placeholder="请输入特约商户号"></el-input>
						</el-form-item>
					</el-form>
					<div style="text-align: center;margin-top: 12px;">
						<el-button type="primary" size="small" @click="wechatAddShow=false">取消</el-button>
						<el-button type="primary" size="small" @click="wechatConfirm()">确认</el-button>
					</div>
				</div>
			</el-dialog>
		</el-dialog>
	
		<el-dialog
		    width="600px"
		    title="费率管理"
		    :visible.sync="costShow"
			destroy-on-close 
			:close-on-click-modal="false">
			<div v-loading="costLoading">
				<el-form :model="costForm" ref="costRef" label-width="124px" label-position="left" :rules="costRules">
					<el-form-item label="支付宝发薪：" prop="ratio">
						<el-input size="small" type="number" v-model="costForm.ratio" placeholder="请输入支付宝发薪费率" clearable>
							<template slot="append"> 每笔  </template>
						</el-input>
						<div style="color: #999;line-height: 22px;">输入0.001即表示千分之一</div>
						<div style="color: #999;line-height: 22px;">实际费率如果低于0.01元，将按照0.01元收取</div>
						<div style="color: #999;line-height: 22px;">输入0即表示完全不收取支付宝发薪手续费</div>
					</el-form-item>
					<el-form-item label="电子保险费率：" prop="insure_commission">
						<el-input size="small" type="number" v-model="costForm.insure_commission" placeholder="请输入保险费率" clearable>
							<template slot="append"> 每笔  </template>
						</el-input>
						<div style="color: #999;line-height: 22px;">输入0.001即表示千分之一</div>
						<div style="color: #999;line-height: 22px;">实际费率如果低于0.01元将不收取手续费</div>
						<div style="color: #999;line-height: 22px;">输入0即表示完全不收取电子保险费用</div>
					</el-form-item>
					
					<el-form-item label="短信验证码：" prop="sms_price">
						<el-input size="small" type="number" v-model="costForm.sms_price" placeholder="请输入短信验证码单价" clearable>
							<template slot="append">元/条</template>
						</el-input>
						<div style="color: #999;line-height: 22px;">输入0即表示完全不收取短信验证码费用</div>
					</el-form-item>
					
					<el-form-item label="电子合同：" prop="contract_price">
						<el-input size="small" type="number" v-model="costForm.contract_price" placeholder="请输入电子合同单价" clearable>
							<template slot="append">元/份</template>
						</el-input>
						<div style="color: #999;line-height: 22px;">输入0即表示完全不收取电子合同费用</div>
					</el-form-item>
					
					<el-form-item label="身份证二要素：" prop="ID_ver_price">
						<el-input size="small" type="number" v-model="costForm.ID_ver_price" placeholder="请输入身份证二要素单价" clearable>
							<template slot="append">元/次</template>
						</el-input>
						<div style="color: #999;line-height: 22px;">输入0即表示完全不收取身份证二要素费用</div>
					</el-form-item>
				</el-form>
				
				<div style="text-align: center;margin-top: 28px;">
					<el-button size="medium" @click="costShow = false">取 消</el-button>
					<el-button size="medium" type="primary" @click="costConfirm()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	
		<alipay-isv :visible.sync="alipayShow" :shopId="shop_id"></alipay-isv>
		<alipay-insurance :visible.sync="alipayInsuranceShow" :shopId="shop_id"></alipay-insurance>
		<wechat-insurance :visible.sync="wechatInsuranceShow" :shopId="shop_id"></wechat-insurance>
		
		<xsk-manage :managehide.sync="imgplugin" @confirm="confirmimg" :selectarr="[]" :selectobjs="[]"></xsk-manage>
	</div>
</template>

<script>
	import {eltips,copylink,validatePassword,httpurl,hasPermissions} from '@/util/util.js'
	import alipayIsv from './components/alipayIsv.vue'
	import alipayInsurance from './components/alipayInsurance.vue'
	import wechatInsurance from './components/wechatInsurance.vue'
	export default {
		name: "accountList",
		components:{alipayIsv,alipayInsurance,wechatInsurance},
		data() {
			return {
				hasPermissions:hasPermissions,
				loading:false,
				list:[],
				page:1,
				total:0,
				shop_id:'',
				styleShow:false,
				styleForm:{
					name:'',
					recruit_name:'',
					shop_name:'',
					introduce:'',
					bj_img:'',
					logo:'',
					company_name:'',
					copyright_is_show:1,//版权信息
				},
				rules:{
					name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
					recruit_name: [{ required: true, message: '请输入招聘端', trigger: 'blur' }],
					shop_name: [{ required: true, message: '请输入系统全称', trigger: 'blur' }],
					introduce: [{ required: true, message: '请输入系统简介', trigger: 'blur' }],
					logo: [{ required: true, message: '请上传logo', trigger: 'change' }],
					bj_img: [{ required: true, message: '请上传背景图', trigger: 'change' }],
					company_name: [{ required: true, message: '请输入公司全称', trigger: 'blur' }],
				},
				imgplugin:false,
				saveLoading:false,
				customer_id:'',
				permissionShow:false,
				permissionLoading:false,
				permissionsList:[],
				user_id:'',
				wechatLoading:true,
				wechatShow:false,
				wechatList:[],
				wechatId:'',
				sub_mchid:'',
				company_name:'',
				wechatAddShow:false,
				wechatAddLoading:false,
				alipayShow:false,
				alipayInsuranceShow:false,
				wechatInsuranceShow:false,
				costForm:{
					ratio:'',
					insure_commission:'',
					sms_price:'',
					contract_price:'',
					ID_ver_price:'',
				},
				costRules:{
					ratio: [{ required: true, message: '请输入支付宝发薪费率', trigger: 'blur' }],
					insure_commission: [{ required: true, message: '请输入保险费率', trigger: 'blur' }],
					sms_price: [{ required: true, message: '请输入短信验证码单价', trigger: 'blur' }],
					contract_price: [{ required: true, message: '请输入电子合同单价', trigger: 'blur' }],
					ID_ver_price: [{ required: true, message: '请输入身份证二要素单价', trigger: 'blur' }],
				},
				costShow:false,
				costLoading:false,
			};
		},
		created() {
			this.getList()
			this.getPermissionList()
		},
		methods:{
			costConfirm(){
				this.$refs['costRef'].validate((valid) => {
				    if(!valid) return;
					this.costLoading = true
					let data = this.costForm
					data.shop_id = this.shop_id
					this.axios.post('/api/main/User/setCost',data).then(res=>{
						this.costLoading = false
						if(res.code==200){
							this.costShow = false
							eltips(res.msg,'success')
							this.getList()
					   	}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			openRate(e){
				this.shop_id = e.shop_id
				this.costForm = {
					ratio:e.ratio,
					insure_commission:e.insure_commission,
					sms_price:e.sms_price,
					contract_price:e.contract_price,
					ID_ver_price:e.ID_ver_price,
				}
				this.costShow = true
			},
			//微信保险
			openWechatInsurance(e){
				this.shop_id = e.shop_id
				this.wechatInsuranceShow = true
			},
			//支付宝保险
			openAlipayInsurance(e){
				this.shop_id = e.shop_id
				this.alipayInsuranceShow = true
			},
			//支付宝发薪
			openAlipay(e){
				this.shop_id = e.shop_id
				this.alipayShow = true
			},
			//微信发薪
			wechatConfirm(){
				if(!this.company_name) return eltips('请输入商户主体名称','error')
				if(!this.sub_mchid) return eltips('请输入特约商户号','error')
				let url = '/api/main/SysTem/addWxMerchant'
				let data = {
					sub_mchid:this.sub_mchid,
					company_name:this.company_name
				}
				if(this.wechatId){
					url = '/api/main/SysTem/setWxMerchant'
					data.id = this.wechatId
				}else{
					data.customer_id = this.customer_id
				}
				this.wechatAddLoading = true
				this.axios.post(url,data).then(res=>{
					this.wechatAddLoading = false
					if(res.code==200){
						this.wechatAddShow = false
						eltips(res.msg,'success')
						this.getWechatList()
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			openWechatEdit(e){
				this.wechatId = e.id
				this.sub_mchid = e.sub_mchid
				this.company_name = e.company_name
				this.wechatAddShow = true
			},
			openWechatAdd(){
				this.wechatId = ''
				this.sub_mchid = ''
				this.company_name = ''
				this.wechatAddShow = true
			},
			openWechat(e){
				this.customer_id = e.customer.id
				this.shop_id = e.shop_id
				this.wechatList = []
				this.wechatLoading = false
				this.wechatShow = true
			},
			getWechatList(){
				this.wechatLoading = true
				let data = {
					shop_id:this.shop_id
				}
				this.axios.get('/api/main/SysTem/getWxMerchant',{params:data}).then(res=>{
					this.wechatLoading = false
					if(res.code==200){
						this.wechatList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			openPremission(id) {
				this.user_id = id
				this.permissionShow = true
			},
			getPermissionList(){
				this.axios.get('/api/getPermissionsOA').then(res=>{
					if(res.code==200){
						this.permissionsList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			//获取指定用户的权限
			getUserPermissions() {
				let data = { user_id: this.user_id }
				this.permissionLoading = true
				this.axios.get('/api/getSelfPermissionsOA', { params: data }).then(res => {
					this.permissionLoading = false
					if (res.code == 200) {
						this.$refs.permissionTree.setCheckedKeys(res.data)
						this.$nextTick(()=>{
							let nowPermission = [...this.$refs.permissionTree.getCheckedKeys(),...this.$refs.permissionTree.getHalfCheckedKeys()]
							let permission = res.data
							const difference = nowPermission.filter((value) => !permission.includes(value));
							difference.map(item => {
								this.$refs.permissionTree.setChecked(item, false);
							})
						})
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//设置权限
			setPermissions() {
				let permissions = this.$refs.permissionTree.getCheckedKeys()
				let halfChecked = this.$refs.permissionTree.getHalfCheckedKeys()
				permissions = [...permissions, ...halfChecked]
				let data = {
					user_id: this.user_id,
					permission_ids: permissions,
				}
				this.permissionLoading = true
				this.axios.post('/api/setSelfPermissionsOA', data).then(res => {
					this.permissionLoading = false
					if (res.code == 200) {
						this.permissionShow = false;
						eltips(res.msg, 'success')
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			
			//界面调整
			saveStyle(){
				this.$refs['styleForm'].validate((valid) => {
					if (!valid) return;
					let data = {
						name:this.styleForm.name,
						recruit_name:this.styleForm.recruit_name,
						shop_name:this.styleForm.shop_name,
						introduce:this.styleForm.introduce,
						bj_img:this.styleForm.bj_img,
						logo:this.styleForm.logo,
						company_name:this.styleForm.company_name,
						shop_id:this.shop_id
					}
					this.saveLoading = true
					this.axios.post('/api/main/SysTem/setInfo',data).then(res=>{
						this.saveLoading = false
						if(res.code==200){
							this.styleShow = false
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			openPlugin(type){
				this.imgType = type
				this.imgplugin = true
			},
			confirmimg(e){
				console.log(e)
				if(e.imgobjs.length>0){
					this.styleForm[this.imgType] = e.imgobjs[0].qiniu_url
					this.$refs['styleForm'].validateField(this.imgType)
				}
			},
			delImg(type){
				this.styleForm[type] = ''
				this.$refs['styleForm'].validateField(type)
			},
			openStyle(item){
				this.shop_id = item.shop_id
				this.styleForm.name = item.name
				this.styleForm.shop_name = item.shop_name
				this.styleForm.introduce = item.introduce
				this.styleForm.bj_img = item.bj_img
				this.styleForm.logo = item.logo
				this.styleForm.copyright_is_show = item.copyright_is_show
				this.styleForm.company_name = item.company_name
				this.styleShow = true
			},
			restPassword(id) {
				this.$prompt('请输入新密码', `重置密码`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator(value) {
						if(!validatePassword(value)) return '密码必须八位以上，且由数字与字母组成，至少包含一位大写字母';
					},
				}).then(({ value }) => {
					let data = { password: value,id:id }
					this.axios.post('/api/main/User/resetPassword', data).then(res => {
						if (res.code == 200) {
							eltips(res.msg, 'success')
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			copylink(shopid){
				let url = httpurl+'/login?shopId='+shopid
				copylink(url)
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				this.loading = true
				this.axios.get('/api/main/User/list',{params:{page:this.page}}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#accountList{
		min-height: calc(100vh - 70px);
	}
	
	#accountList .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		// margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
	
	.uploadbox{
		display: flex;
		flex-wrap: wrap;
		.imgbox{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border: 1px solid #DCDFE6;
			width: 120px;
			height:120px;
			margin-right: 8px;
			margin-bottom: 8px;
			position: relative;
			.img{
				width: 100%;
				height: 100%;
			}
			.addIcon{
				font-size: 34px;
				line-height: 1;
				color: #DCDFE6;
			}
			.addtips{
				font-size: 12px;
				color: #DCDFE6;
				margin-top: 8px;
				line-height: 1;
			}
			.delicon {
				display: block;
				font-size: 20px;
				position: absolute;
				right: -8px;
				top: -8px;
			}
		}
	}
</style>