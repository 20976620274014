<template>
	<div id="conponentAlipayIsv">
		<el-dialog title="支付宝发薪管理" 
			:visible.sync="innerVisible" 
			width="800px" 
			destroy-on-close 
			:close-on-click-modal="false" 
			:close-on-press-escape="false"
			@open="getAlipayList('cover')"
			@close="closeDialog">
			<div v-loading="alipayLoading">
				<div style="margin-bottom: 18px;">
					<div>
						<el-button type="primary" size="small" @click="getAilpaySignLink()">获取发薪签约二维码</el-button>
						<el-button type="primary" size="small" @click="getAlipayList()">刷新列表</el-button>
					</div>
					<el-alert
						style="margin-top: 10px;"
						:closable="false"
					    title="请提前引导客户完成企业支付宝注册,非企业支付宝账号签约会导致出资异常!!!"
						show-icon
					    type="warning">
					</el-alert>
				</div>
				<el-table :key="Math.random()" :data="alipayList" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
					<el-table-column label="主体名称" prop="company_name" align="center"/>
					<el-table-column label="协议号" prop="agreement_no" align="center" width="200"/>
					<el-table-column label="协议状态" prop="status" align="center" width="120">
						<template slot-scope="scope">
							<span style="color:#1989fa;" v-if="scope.row.status=='NORMAL'">正常</span>
							<span style="color:#F56C6C;" v-else-if="scope.row.status=='STOP'">已暂停</span>
							<span style="color:#F56C6C;" v-else>处理中</span>
						</template>
					</el-table-column>
					<el-table-column label="生效时间" prop="valid_time" align="center" width="160"/>
				</el-table>
			</div>
		</el-dialog>
		
		<el-dialog
		    width="380px"
		    title="获取签约二维码"
		    :visible.sync="alipayResult"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			destroy-on-close>
			<el-result 
				:icon="alipaySignCode==200?'success':'error'" 
				:title="alipaySignCode==200?alipayCompany:'获取失败'" 
				:subTitle="alipaySignCode!=200?`${alipayErrMsg||'服务器错误'}`:'签约码获取成功，请引导客户使用企业支付宝扫码签约，签约完成前请勿关闭此窗口'">
			    <template slot="extra" v-if="alipaySignCode==200">
			        <el-button type="primary" size="medium" @click="openLink(alipaySign)">打开二维码</el-button>
			        <el-button type="primary" size="medium" @click="alipayBindConfirm()">已完成签约</el-button>
			    </template>
			</el-result>
		</el-dialog>
	</div>
</template>

<script>
	import {eltips} from '@/util/util.js'
	export default {
		name: "conponentAlipayIsv",
		data() {
			return {
				alipayLoading:true,
				alipayList:[],
				alipaySign:null,
				alipaySignCode:0,
				alipayErrMsg:'',
				alipayCompany:'',
				alipayResult:false,
			};
		},
		props:{
			visible: {
				type: Boolean,
				default: false
			},
			shopId: {
				type: [String,Number],
				default: ''
			},
		},
		computed: {
		    innerVisible: {
		      get() {
		        return this.visible;
		      },
		      set(value) {
		        this.$emit("update:visible", value);
		      },
		    },
		},
		created() {
			
		},
		methods:{
			closeDialog(){
				this.$emit('update:visible',false)
			},
			//支付宝发薪
			openLink(link){
				window.open(link,'_blank')
			},
			restAlipayCode(){
				this.alipaySignCode = 0
				this.alipayErrMsg = ''
				this.alipayCompany = ''
				this.alipaySign = null
				this.alipayResult = false
				this.alipayLoading = false
			},
			alipayBindConfirm(){
				this.$confirm('请确认用户签约已完成，此窗口关闭后将无法再次打开。', '签约已完成', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
				    this.alipayResult = false
					this.getAlipayList()
				}).catch(() => {});
			},
			getAilpaySignLink(){
				this.restAlipayCode()
				this.$prompt('用户签约前请勿重复获取签约二维码！！！', '获取签约二维码', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
					closeOnClickModal:false,
					inputPlaceholder:'请输入企业支付宝主体名称',
					inputValidator(value) {
						if (!value) return '主体名称不能为空'
						if (value.replace(/\s/g, '').length < 1) return '主体名称不能为空'
					}
				}).then(({ value }) => {
					const data = {
						company_name:value,
						shop_id:this.shopId
					}
				    this.alipayLoading = true
				    this.axios.post('/api/main/Isv/get_signing_link',data).then(res=>{
				    	this.alipayLoading = false
				    	this.alipaySignCode = res.code
				    	if(res.code==200){
							this.alipayCompany = data.company_name
				    		this.alipaySign = res.data
							this.getAlipayList()
				    	}else{
							this.alipayErrMsg = res.msg
				    	}
						this.alipayResult = true
				    })
				}).catch(() => {});
			},
			getAlipayList(cover){
				if(cover) this.alipayList = []
				let data = { shop_id:this.shopId }
				this.alipayLoading = true
				this.axios.get('/api/main/Isv/getIsvSignList',{params:data}).then(res=>{
					this.alipayLoading = false
					if(res.code==200){
						this.alipayList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
		}
	};
</script>

<style lang="less" scoped>
	#conponentAlipayIsv{
		
	}
</style>