<template>
	<div id="conponentAlipayInsurance">
		<el-dialog title="支付宝保险管理" 
			:visible.sync="innerVisible" 
			width="800px" 
			destroy-on-close 
			:close-on-click-modal="false" 
			:close-on-press-escape="false"
			@open="getList('cover')"
			@close="closeDialog">
			<div v-loading="loading">
				<div style="margin-bottom: 18px;">
					<div>
						<el-button type="primary" size="small" @click="openSignForm()">获取保险签约二维码</el-button>
						<!-- <el-button type="primary" size="small" @click="getList()">刷新列表</el-button> -->
					</div>
					<el-alert
						style="margin-top: 10px;"
						:closable="false"
					    title="请提前引导客户完成企业支付宝注册,非企业支付宝账号签约会导致出资异常!!!"
						show-icon
					    type="warning">
					</el-alert>
				</div>
				<el-table :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
					<el-table-column label="主体名称" prop="name" align="center"/>
					<el-table-column label="意外险" prop="agreement_no" align="center" width="140">
						<template slot-scope="scope">
							<div v-if="scope.row.acc_signStatus=='ALREADY_SIGNED'">
								<div style="color:#1989fa;">已签约</div>
								<!-- <div>{{scope.row.acc_effectEndTime}}到期</div> -->
							</div>
							<span style="color:#F56C6C;" v-else-if="scope.row.acc_signStatus=='SIGNED_EXPIRE'">签约已过期</span>
							<span style="color:#F56C6C;" v-else>处理中</span>
						</template>
					</el-table-column>
					<el-table-column label="雇主险" prop="status" align="center" width="140">
						<template slot-scope="scope">
							<div v-if="scope.row.emp_signStatus=='ALREADY_SIGNED'">
								<div style="color:#1989fa;">已签约</div>
								<!-- <div>{{scope.row.emp_effectEndTime}}到期</div> -->
							</div>
							<span style="color:#F56C6C;" v-else-if="scope.row.emp_signStatus=='SIGNED_EXPIRE'">签约已过期</span>
							<span style="color:#F56C6C;" v-else>处理中</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center" width="140">
						<template slot-scope="scope">
							<template>
								<el-button type="primary" size="small" @click="uploadSign(scope.row.id,scope.$index)">更新签约情况</el-button>
							</template>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
		
		<el-dialog
		    width="600px"
		    title="获取签约二维码"
		    :visible.sync="getCodeShow"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			destroy-on-close>
			<div v-loading="getCodeLoading">
				<el-alert
					style="margin-bottom: 20px;"
					:closable="false"
				    title="签约流程未完成前,请勿重复获取签约二维码！！！"
					show-icon
				    type="warning">
				</el-alert>
				<el-form ref="signRef" :model="signForm" :rules="rules" label-width="130px">
					<el-form-item label="主体名称" prop="name">
						<el-input size="small" v-model="signForm.name" placeholder="请输入企业支付宝主体名称"></el-input>
					</el-form-item>
					<el-form-item label="统一社会信用码" prop="socialCreditCode">
						<el-input size="small" v-model="signForm.socialCreditCode" placeholder="请输入同主体的统一社会信用码"></el-input>
					</el-form-item>
					<el-form-item label="支付宝账号" prop="alipayAccount">
						<el-input size="small" v-model="signForm.alipayAccount" placeholder="请输入企业支付宝账号"></el-input>
					</el-form-item>
					<el-form-item label="支付宝商户号" prop="pid">
						<el-input size="small" v-model="signForm.pid" placeholder="请输入企业支付宝商户号 2088开头号码"></el-input>
					</el-form-item>
					<el-form-item label="联系人电话" prop="phone">
						<el-input size="small" v-model="signForm.phone" placeholder="请输入联系电话"></el-input>
					</el-form-item>
				</el-form>
				
				<div style="text-align: center;margin-top: 12px;">
					<el-button type="primary" size="medium" @click="getCodeShow=false">取消</el-button>
					<el-button type="primary" size="medium" @click="getSignLink()">确认</el-button>
				</div>
			</div>
		</el-dialog>
		
		<el-dialog
		    width="380px"
		    title="获取签约二维码"
		    :visible.sync="resultShow"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			destroy-on-close>
			<el-result 
				:icon="signUrlCode!=200?'error':''" 
				:title="signUrlCode==200?companyName:'获取失败'" 
				:subTitle="signUrlCode!=200?`${errorMsg||'服务器错误'}`:'签约码获取成功，请引导客户使用企业支付宝扫码签约，签约完成前请勿关闭此窗口'">
			    <template slot="icon" v-if="signUrlCode==200">
					<vue-qr :text="signUrl" :size="200" style="display: block; margin:0"></vue-qr>
			    </template>
				<template slot="extra">
			        <!-- <el-button type="primary" size="medium" @click="openLink(signUrl)">打开二维码</el-button> -->
					<template v-if="signUrlCode==200">
						<el-button type="primary" size="medium" @click="signBindConfirm()">已完成签约</el-button>
					</template>
					<template v-else>
						<el-button type="primary" size="medium" @click="resultShow=false">重新填写</el-button>
					</template>
			    </template>
			</el-result>
		</el-dialog>
	</div>
</template>

<script>
	import {eltips} from '@/util/util.js'
	import VueQr from 'vue-qr'
	export default {
		name: "conponentAlipayInsurance",
		components:{VueQr},
		data() {
			return {
				loading:false,
				list:[],
				signUrl:null,
				signUrlCode:0,
				errorMsg:'',
				companyName:'',
				resultShow:false,
				signForm:{
					name:'',
					socialCreditCode:'',
					alipayAccount:'',
					phone:'',
					pid:'',
				},
				rules:{
					name:[{required: true, message: '请输入企业支付宝主体名称', trigger: 'blur'}],
					socialCreditCode:[{required: true, message: '请输入统一社会信用码', trigger: 'blur'}],
					alipayAccount:[{required: true, message: '请输入企业支付宝账号', trigger: 'blur'}],
					phone:[{required: true, message: '请输入联系人电话', trigger: 'blur'}],
					pid:[{required: true, message: '请输入企业支付宝商户号', trigger: 'blur'}],
				},
				getCodeShow:false,
				getCodeLoading:false,
			};
		},
		props:{
			visible: {
				type: Boolean,
				default: false
			},
			shopId: {
				type: [String,Number],
				default: ''
			},
		},
		computed: {
		    innerVisible: {
		      get() {
		        return this.visible;
		      },
		      set(value) {
		        this.$emit("update:visible", value);
		      },
		    },
		},
		created() {
			
		},
		methods:{
			//更新签约状态
			uploadSign(id,index){
				this.loading = true
				this.axios.get(`/api/main/ali/getSignInfo?id=${id}`).then(res=>{
					this.loading = false
					if(res.code==200){
						let obj = this.list[index]
						res.data.response.signList.map(e=>{
							if(e.insureTypeCode=='ACCIDENT'){
								obj.acc_signStatus = e.signStatus
								if(e.signStatus=='ALREADY_SIGNED'){
									obj.acc_effectEndTime = e.effectEndTime
								}
							}
							if(e.insureTypeCode=='EMPLOYER_LIABILITY'){
								obj.emp_signStatus = e.signStatus
								if(e.signStatus=='ALREADY_SIGNED'){
									obj.emp_effectEndTime = e.effectEndTime
								}
							}
						})
						this.list.splice(index,1,obj)
						eltips(res.msg,'success')
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			closeDialog(){
				this.$emit('update:visible',false)
			},
			openLink(link){
				window.open(link,'_blank')
			},
			restResultInfo(){
				this.signUrlCode = 0
				this.errorMsg = ''
				this.companyName = ''
				this.signUrl = null
				this.resultShow = false
				this.loading = false
			},
			signBindConfirm(){
				this.$confirm('请确认用户签约已完成，此窗口关闭后将无法再次打开。', '签约已完成', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
				    this.resultShow = false
					this.getList()
				}).catch(() => {});
			},
			//获取签约链接
			openSignForm(){
				this.signForm = {
					name:'',
					socialCreditCode:'',
					alipayAccount:'',
					phone:'',
					pid:'',
				}
				this.getCodeShow = true
			},
			getSignLink(){
				this.$refs['signRef'].validate((valid) => {
					if(!valid) return
					const data = {
						name:this.signForm.name,
						socialCreditCode:this.signForm.socialCreditCode,
						alipayAccount:this.signForm.alipayAccount,
						phone:this.signForm.phone,
						pid:this.signForm.pid,
						shop_id:this.shopId,
					}
					this.getCodeLoading = true
					this.axios.post('/api/main/ali/getSignUrl',data).then(res=>{
						this.getCodeLoading = false
						this.signUrlCode = res.code
						if(res.code==200){
							this.companyName = data.name
							this.signUrl = res.data.response.signUrl
							this.getCodeShow = false
						}else{
							this.errorMsg = res.msg
						}
						this.getList()
						this.resultShow = true
					})
				});
			},
			getList(cover){
				if(cover) this.list = []
				let data = { shop_id:this.shopId }
				this.loading = true
				this.axios.get('/api/main/ali/get_ali_lobar_list',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.list = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
		}
	};
</script>

<style lang="less" scoped>
	#conponentAlipayInsurance{
		
	}
</style>