document.title = '仁立通销售管理系统'
import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
// import '../theme/index.css'
import 'view-design/dist/styles/iview.css';
import App from './App.vue'
import router from './router'
import store from './store'
import Layout from './layout/index.vue'
import './router/permission'
import axios from 'axios'
import './icons'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

Vue.prototype.$dayjs = dayjs

import { tim } from '@/util/tim.js'
Vue.prototype.$tim = tim

import * as util from '@/util/util.js'
Vue.prototype.$util = util

import { hasPermissions } from '@/util/util.js'
Vue.prototype.$hasPermissions = hasPermissions

import VueJsonp from 'vue-jsonp'
Vue.use(VueJsonp)

import plugins from './components/common/index.js'
Vue.use(plugins)

import _ from 'lodash'
Vue.prototype._ = _ 

import Meta from "vue-meta";
Vue.use(Meta);

import Print from 'vue-print-nb'
Vue.use(Print);

Vue.prototype.$bus=new Vue({})

import { DatePicker } from 'view-design';
import {resetRouter} from './router/index.js'
Vue.component('DatePicker', DatePicker);

Vue.use(ElementUI);

Vue.config.productionTip = false
axios.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem("token");
        if (token) config.headers["Authorization"] = 'Bearer' + token;
		config.headers["guardName"] = 'main_admin'
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

axios.interceptors.response.use(
  response => {
    //拦截响应，做统一处理 
	if(response.data.code==401){
		if(store.state.sdk_ready){
			tim.logout()
		}
		if(sessionStorage.getItem('userinfo')){
			let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
			let type = userinfo.type
			let guard_name = userinfo.guard_name
			let guard_info = {
				type:type,
			}
			sessionStorage.setItem('guard_info',JSON.stringify(guard_info))
			//判断登出到某个后台登录页面
			switch(guard_name){
				default:
					router.replace({path: "/login"})
					break;
				case 'orange_admin_user': //新零售分后台
					guard_info.guard_name = 'orange_admin_user'
					router.replace({path: "/orangelogin"})
					break;
			}
		}else{
			router.push({path: "/login"})
		}
		resetRouter()
		store.commit('routestatus', 0)
		sessionStorage.removeItem('IMtoken')
		sessionStorage.removeItem('routestext')
		sessionStorage.removeItem('routes')
		sessionStorage.removeItem('userinfo')
		sessionStorage.removeItem('token')
		sessionStorage.removeItem('isApply')
	}else{
		return response.data
	}
  },
 )
Vue.prototype.axios=axios

new Vue({
  router,
  store,
  axios,
  render: h => h(Layout)
}).$mount('#app')
